import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "@layout";
import SEO from "@components/seo";
import PageBreadcrumb from "../../components/pagebreadcrumb";
import AwardPhotosDataInit from "../../components/photosdata/awards.json";
import PhotosData from "../../components/photosdata/Data";
import { normalizedData } from "@utils/functions";
import { Container } from "react-bootstrap";
import axios from "axios";
import { useEffect, useState } from "react";
const AwardPhotoGallery = ({ data, location, pageContext }) => {
    const [AwardPhotosData, setJsonData] = useState(AwardPhotosDataInit);
    useEffect(() => {
        axios(
            "https://admin.reliance.com.bd/api/sendJSON/62a74f237887159c5296ddf6"
        )
            .then((resultData) => {
                setJsonData(resultData.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const globalContent = normalizedData(data?.allGeneral?.nodes || []);
    return (
        <Layout
            data={{
                ...globalContent["menu"],
                ...globalContent["footer"],
            }}
        >
            <SEO title="Award Photos Gallery" pathname="/" />
            <PageBreadcrumb
                pageContext={pageContext}
                location={location}
                title="Award Photos Gallery"
            />
            <Container>
                <div className="row">
                    <PhotosData data={AwardPhotosData} />
                </div>
            </Container>
        </Layout>
    );
};
AwardPhotoGallery.propTypes = {
    location: PropTypes.object,
    pageContext: PropTypes.object,
    data: PropTypes.shape({
        allGeneral: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
        allArticle: PropTypes.shape({
            nodes: PropTypes.arrayOf(PropTypes.shape({})),
        }),
    }),
};

export const query = graphql`
    query AwardPhotoGalleryQuery {
        allGeneral {
            nodes {
                section
                id
                menu {
                    ...Menu
                }
                footer {
                    ...Footer
                }
            }
        }
        allArticle {
            nodes {
                ...Articles
            }
        }
    }
`;

export default AwardPhotoGallery;
